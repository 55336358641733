.playground {
  position: absolute;
  width: 100%;
  height: calc(100vh - var(--navbar-height));
  left: 0;
  overflow: hidden;
  background-color: var(--bg);
  transition: var(--theme-transition-bg), opacity .2s ease-out, transform .2s ease-out;
  z-index: 1;
}

.playground-container {
  height: calc(100vh - var(--navbar-height) - 3rem - 1px);
  z-index: 1;
  display: flex;
  border-top: 2px solid grey;
  box-sizing: border-box;
}

.divider {
  height: .6rem;
  cursor: row-resize;
  background-color: var(--brand);
  border: 1px solid #2c3e50;
  z-index: 2;
  touch-action: none;
  position: relative;
  box-shadow: 0 8px 15px 0px var(--shadow);
  box-sizing: border-box;
  user-select: none;
}

.drag-handle {
  box-shadow: 0 0 20px 0 var(--shadow);
  border-radius: 1.25rem;
  padding: 0;
  border: none;
  background-color: transparent;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: grab;
  user-select: none;
}

.drag-handle:active {
  cursor: grabbing;
}

.drag-handle img {
  width: 2.5rem;
  vertical-align: bottom;
}

.playground-container {
  flex-direction: column;
  --content-height: calc((100% - var(--navbar-height) - 3rem - 5px) / 2);
  --content-top: calc(var(--navbar-height) + 3rem);
}

.playground-container .playground-editor {
  width: 100% !important;
  height: 50% !important;
}

.playground-container .live-container {
  width: 100%;
  flex: 1;
}

.playground-container .live-container iframe {
  background-color: var(--live-bg);
  transition: var(--theme-transition-bg);
}

@media (min-width: 768px) {
  .playground-container {
    flex-direction: row;
  }

  .playground-container .playground-editor {
    width: 50% !important;
    height: 100% !important;
  }

  .playground-container .live-container {
    height: 100%;
  }

  .divider {
    width: .4rem;
    height: 100%;
    cursor: col-resize;
  }

  .drag-handle img {
    transform: rotate(90deg);
    width: 2.2rem;
  }
}

@media (min-width: 1000px) {
  .playground-container .playground-editor {
    width: 60% !important;
  }
}
