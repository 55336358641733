.code-block-container {
  position: relative;
}

.code-block code {
  white-space: pre-wrap !important;
  word-break: break-all !important;
}

.code-block-container button {
  border: 0;
  background-color: transparent;
  padding: .5rem;
  cursor: pointer;
  opacity: .8;
  transition: opacity .1s linear;
  height: min-content;
}

.code-block-container button:hover {
  opacity: 1;
}

.code-block {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
  padding-top: 0 !important;
  box-shadow: 0 0 20px var(--shadow);
  font-size: .9rem !important;
  flex-grow: 1;
  position: relative;
  z-index: 5;
}

.toolbar {
  padding: 0 0.3rem 0 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 2rem;
  position: relative;
  z-index: 10;
}

.toolbar .status {
  color: rgb(139, 139, 139);
  font-size: .9rem;
  height: 100%;
  line-height: 2rem;
}

.code-icon {
  height: 1rem;
  vertical-align: bottom;
}

@media (min-width: 768px) {
  .code-block {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}

@media (min-width: 1000px) {
  .code-block {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
}
