.four-oh-four {
  text-align: center;
  height: calc(100vh - var(--navbar-height) - 5.5rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.four-oh-four h1 {
  font-weight: 500;
}

.four-oh-four,
.four-oh-four a {
  color: var(--text);
  transition: var(--theme-transition-text);
}

blockquote {
  font-size: 1rem;
  color: var(--text-quote);
  border-left: 0.2rem solid #e9e9e9;
  margin: 1rem 0;
  padding: 0.25rem 0.5rem 0.25rem 1rem;
}
