button.theme-button {
  outline: none;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  position: relative;
  cursor: pointer;
}

.theme-icon {
  height: calc(var(--navbar-height) - 2 * var(--navbar-padding-v));
  opacity: 0;
  transition: opacity .15s linear, transform .15s linear;
}

img.theme-icon:not(:first-child) {
  position: absolute;
  left: 0;
}

.theme-icon.active {
  opacity: 1;
  transform: rotateZ(360deg);
}
